
import {defineComponent} from "vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "UserLink",
  components: {},
  props: {
    user: {required: true},
    showName: {default:true}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
