
import {computed, defineComponent} from "vue";
import {useLoadingStore} from "@/store/loading";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";

export default defineComponent({
  name: "SearchHorizontal",
  components: {EntityLoading},
  setup() {
    const store = useLoadingStore();
    const loading = computed(() => store.entitiesLoading)
    return {
      loading,
    }
  }
})
